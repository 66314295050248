import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TweetEmbed from "react-tweet-embed";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Uber has built a cool suite of data visualization tools for WebGL. Let's
explore with a real-time dataset of global airplane positions.`}</p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/YGv1LNgKbn4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/6yqx23v6mn?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Giving up on `}<a parentName="p" {...{
        "href": "https://reactviz.holiday/lumagl-pt1/"
      }}>{`luma.gl`}</a>{` as too low level, we tried something else: `}<a parentName="p" {...{
        "href": "http://deck.gl"
      }}>{`Deck.gl`}</a>{`. Same suite of WebGL React tools from Uber but higher level and therefore more fun.`}</p>
    <p>{`Of course Deck.gl is built for maps so we had to make a map. What better way to
have fun with a map than drawing live positions of all airplanes in the sky?`}</p>
    <p>{`All six thousand of them. Sixty times per second.`}</p>
    <p>{`Yes we can! 💪`}</p>
    <p>{`This is the plan:`}</p>
    <ol>
      <li parentName="ol">{`Fetch data from `}<a parentName="li" {...{
          "href": "https://opensky-network.org"
        }}>{`OpenSky`}</a></li>
      <li parentName="ol">{`Render map with `}<a parentName="li" {...{
          "href": "https://github.com/uber/react-map-gl"
        }}>{`react-map-gl`}</a></li>
      <li parentName="ol">{`Overlay a Deck.gl
`}<a parentName="li" {...{
          "href": "http://deck.gl/#/documentation/deckgl-api-reference/layers/icon-layer"
        }}>{`IconLayer`}</a></li>
      <li parentName="ol">{`Predict each airplane's position on the next Fetch`}</li>
      <li parentName="ol">{`Interpolate positions 60 times per second`}</li>
      <li parentName="ol">{`Update and redraw`}</li>
    </ol>
    <p>{`Our goal is to create a faux live map of airplane positions. We can fetch real
positions every 10 seconds per OpenSky usage policy.`}</p>
    <p>{`You can see the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/webgl-airplanes-map"
      }}>{`full code on GitHub`}</a>{`. No
Codesandbox today because it makes my computer struggle when WebGL is involved.`}</p>
    <TweetEmbed id="1076067452038115328" options={{
      conversation: 'none'
    }} mdxType="TweetEmbed" />
    <p>{`See the airplanes in your browser 👉
`}<a parentName="p" {...{
        "href": "https://create-react-app-gsqfy1eaq.now.sh"
      }}>{`click me 🛩`}</a></p>
    <h2 {...{
      "id": "fetch-data-from-opensky"
    }}>{`Fetch data from OpenSky`}</h2>
    <p>{`OpenSky is a receiver network which continuously collects air traffic
surveillance data. They keep it for forever and make it available via an API.`}</p>
    <p>{`As an anon user you can get real-time data of all the world's airplanes current
positions every 10 seconds. With some finnagling you can get historic data,
super real-time stuff, and so on. We don't need any of that.`}</p>
    <p>{`We `}<inlineCode parentName="p">{`fetchData`}</inlineCode>{` in `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`. Parse each entry into an object, update
local state, and start the animation. Also schedule the next fetch.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`componentDidMount() {
    this.fetchData();
}

fetchData = () => {
    d3.json("https://opensky-network.org/api/states/all").then(
        ({ states }) =>
            this.setState(
                {
                    // from https://opensky-network.org/apidoc/rest.html#response
                    airplanes: states.map(d => ({
                        callsign: d[1],
                        longitude: d[5],
                        latitude: d[6],
                        velocity: d[9],
                        altitude: d[13],
                        origin_country: d[2],
                        true_track: -d[10],
                        interpolatePos: d3.geoInterpolate(
                            [d[5], d[6]],
                            destinationPoint(
                                d[5],
                                d[6],
                                d[9] * this.fetchEverySeconds,
                                d[10]
                            )
                        )
                    }))
                },
                () => {
                    this.startAnimation();
                    setTimeout(
                        this.fetchData,
                        this.fetchEverySeconds * 1000
                    );
                }
            )
    );
};
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.json`}</inlineCode>{` fetches JSON data from a URL, returns a promise. We map through the
data and assign indexes to representative object keys. Makes the other code
easier to read.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`setState`}</inlineCode>{` callback, we start the animation and use a `}<inlineCode parentName="p">{`setTimeout`}</inlineCode>{` to
call `}<inlineCode parentName="p">{`fetchData`}</inlineCode>{` again in 10 seconds. More about teh animation in a bit.`}</p>
    <h2 {...{
      "id": "render-map-with-react-map-gl"
    }}>{`Render map with react-map-gl`}</h2>
    <p>{`Turns out rendering a map with Uber's react-map-gl is really easy. The library
does everything for you.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { StaticMap } from 'react-map-gl'
import DeckGL, { IconLayer } from "deck.gl";

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = '<your token>'

// Initial viewport settings
const initialViewState = {
  longitude: -122.41669,
  latitude: 37.7853,
  zoom: 5,
  pitch: 0,
  bearing: 0,
}

// ...

<DeckGL
    initialViewState={initialViewState}
    controller={true}
    layers={layers}
>
    <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
</DeckGL>
`}</code></pre>
    <p>{`That is all.`}</p>
    <p>{`You need to create a Mapbox account and get your token, the `}<inlineCode parentName="p">{`initialViewState`}</inlineCode>{`
I copied from Uber's docs. It points to San Francisco.`}</p>
    <p>{`In the render method you then return `}<inlineCode parentName="p">{`<DeckGL`}</inlineCode>{` which sets up the layering
stuff, and plop a `}<inlineCode parentName="p">{`<StaticMap>`}</inlineCode>{` inside. This gives you pan and zoom behavior
out of the box. I'm sure with some twiddling you could get cool views and
rotations and all sorts of 3D stuff.`}</p>
    <p>{`I say that because I've seen pics in Uber docs :P`}</p>
    <h2 {...{
      "id": "overlay-a-deckgl-iconlayer"
    }}>{`Overlay a Deck.gl IconLayer`}</h2>
    <p>{`That `}<inlineCode parentName="p">{`layers`}</inlineCode>{` prop needs a list of layers. You're meant to create a new copy on
every render, but internally Deck.gl promises to keep things memoized and
figure out a minimal set of changes necessary. How they do that I don't know
and as long as it works it doesn't really matter how.`}</p>
    <p>{`We configure the icon layer like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Airplane from './airplane-icon.jpg';

const layers = [
  new IconLayer({
    id: 'airplanes',
    data: this.state.airplanes,
    pickable: false,
    iconAtlas: Airplane,
    iconMapping: {
      airplane: {
        x: 0,
        y: 0,
        width: 512,
        height: 512,
      },
    },
    sizeScale: 20,
    getPosition: d => [d.longitude, d.latitude],
    getIcon: d => 'airplane',
    getAngle: d => 45 + (d.true_track * 180) / Math.PI,
  }),
];
`}</code></pre>
    <p>{`We name it `}<inlineCode parentName="p">{`airplanes`}</inlineCode>{` because it's showing airplanes, pass in our data, and
define the airplane icon. `}<inlineCode parentName="p">{`iconAtlas`}</inlineCode>{` is a sprite and the mapping specifies
which parts of the image map to which name. With just one icon in the image
that's pretty quick.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`getPosition`}</inlineCode>{` to fetch longitude and latitude from each airplane and
pass it to the drawing layer. `}<inlineCode parentName="p">{`getIcon`}</inlineCode>{` specifies that we're rendering the
`}<inlineCode parentName="p">{`airplane`}</inlineCode>{` icon and `}<inlineCode parentName="p">{`getAngle`}</inlineCode>{` rotates everything first by 45 degrees because
our icon is weird, and then by the direction of the airplane from our data.`}</p>
    <p><inlineCode parentName="p">{`true_track`}</inlineCode>{` is the airplane's bearing in radians so we transform it to degrees
with some math.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/blob/master/src/content/webgl-airplanes/airplane-icon.jpg?raw=true",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "predict-airplanes-next-position"
    }}>{`Predict airplanes' next position`}</h2>
    <p>{`Predicting each airplane's position 10 seconds from now is ... mathsy.
Positions are in latitudes and longitudes, velocities are in meters per second.`}</p>
    <p>{`I'm not so great with spherical euclidean maths so I borrowed the solution from
`}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/19352921/how-to-use-direction-angle-and-speed-to-calculate-next-times-latitude-and-longi"
      }}>{`StackOverflow`}</a>{`
and made some adjustments to fit our arguments.`}</p>
    <p>{`We use that to create a `}<inlineCode parentName="p">{`d3.geoInterpolate`}</inlineCode>{` interpolator between the start and
end point. That enables us to feed in numbers between 0 and 1 and get airplane
positions at specific moments in time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`interpolatePos: d3.geoInterpolate(
  [d[5], d[6]],
  destinationPoint(d[5], d[6], d[9] * this.fetchEverySeconds, d[10])
);
`}</code></pre>
    <p>{`Gobbledygook. Almost as bad as the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/webgl-airplanes-map/blob/master/src/destinationPoint.js"
      }}>{`destinationPoint function code`}</a></p>
    <h2 {...{
      "id": "interpolate-and-redraw"
    }}>{`Interpolate and redraw`}</h2>
    <p>{`With that interpolator in hand, we can start our animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`currentFrame = null;
timer = null;

startAnimation = () => {
  if (this.timer) {
    this.timer.stop();
  }
  this.currentFrame = 0;
  this.timer = d3.timer(this.animationFrame);
};

animationFrame = () => {
  let { airplanes } = this.state;
  airplanes = airplanes.map(d => {
    const [longitude, latitude] = d.interpolatePos(
      this.currentFrame / this.framesPerFetch
    );
    return {
      ...d,
      longitude,
      latitude,
    };
  });
  this.currentFrame += 1;
  this.setState({ airplanes });
};
`}</code></pre>
    <p>{`We use a `}<inlineCode parentName="p">{`d3.timer`}</inlineCode>{` to run our `}<inlineCode parentName="p">{`animationFrame`}</inlineCode>{` function 60 times per second.
Or every `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{`. That's all internal and D3 figures out the
best option.`}</p>
    <p>{`Also gotta make sure to stop any existing timers when running a new one :)`}</p>
    <p>{`The `}<inlineCode parentName="p">{`animationFrame`}</inlineCode>{` method itself maps through the airplanes and creates a new
list. On each iteration we copy over the whole datapoint and use the
interpolator we defined earlier to calculate the new position.`}</p>
    <p>{`To get numbers from 0 to 1 we try to predict how many frames we're gonna render
and keep track of which frame we're at. So 0/60 gives 0, 10/60 gives 0.16,
60/60 gives 1 etc. The interpolator takes this and returns geospatial positions
along that path.`}</p>
    <p>{`Of course this can't take into account any changes in direction the airplane
might make.`}</p>
    <p>{`Updating component state triggers a re-render.`}</p>
    <h2 {...{
      "id": "and-thats-cool"
    }}>{`And that's cool`}</h2>
    <p>{`What I find really cool about all this is that even though we're copying and
recreating and recalculating and ultimately redrawing some 6000 airplanes it
works smoothly. Because WebGL is more performant than I ever dreamed possible.`}</p>
    <p>{`We could improve performance further by moving this animation out of React
state and redraw into vertex shaders but that's hard and turns out we don't
have to.`}</p>
    <blockquote className="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">Look at those little WebGL airplanes go! 🛩<br /><br />👉 <a href="https://t.co/Q7rpzKGQHi">https://t.co/Q7rpzKGQHi</a> <a href="https://t.co/xOMUJk1J2Z">pic.twitter.com/xOMUJk1J2Z</a></p>&mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1076067452038115328?ref_src=twsrc%5Etfw">December 21, 2018</a></blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      